import React from "react";
import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { simuParameters } from "../App";
import DxfParser from 'dxf-parser';
import '../styles/popup.css'; // Adjust the path as necessary

let imageState = 3;
//const fingers_min = 1;
//const url = "/image_preview/";

function ImageSection({imageOptions,setimageOptions}){
  const fileRef = React.useRef();

  const [fingerOptions, setFingerOptions] = useState({
    fingerSize: 30,
    fingerNumber: 1,
  });
  const [simuParams,] = useRecoilState(simuParameters);
  const [showLayerPopup, setShowLayerPopup] = useState(false);
  const [dxfLayers, setDxfLayers] = useState([]);
  const [selectedLayers, setSelectedLayers] = useState({});

  // const fetchMask = async () => {
  //   const formData = new FormData();
  //   console.log(imageOptions);
  //   if (imageOptions.fileType === "image" && imageOptions.rawImage && imageOptions.rawImage.startsWith("data:image/")) {
  //     const base64Image = imageOptions.rawImage.split(",")[1];
  //     const byteCharacters = atob(base64Image);
  //     const byteNumbers = new Array(byteCharacters.length)
  //       .fill(0)
  //       .map((_, i) => byteCharacters.charCodeAt(i));
  //     const byteArray = new Uint8Array(byteNumbers);
  //     const blob = new Blob([byteArray], { type: "image/jpeg" });
  //     formData.append("file", blob, "image.jpg");
  //   } else if (imageOptions.fileType === "dxf" && imageOptions.rawImage && imageOptions.layers.length > 0) {
  //     const dxfBlob = new Blob([imageOptions.rawImage], { type: "application/dxf" });
  //     formData.append("file", dxfBlob, "file.dxf");
  //     formData.append("layers", JSON.stringify(imageOptions.layers));
  //   } else {
  //     console.error("No valid file to upload or unsupported file type");
  //     return;
  //   }
  
  //   try {
  //     const response = await axios.post(url, formData, {
  //       responseType: "arraybuffer",
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       }
  //     });
  
  //     if (response.status === 200) {
  //       const image = btoa(
  //         new Uint8Array(response.data).reduce(
  //           (data, byte) => data + String.fromCharCode(byte),
  //           ""
  //         )
  //       );
  
  //       setsimuParams({
  //         ...simuParams,
  //         mask: `data:${response.headers["content-type"].toLowerCase()};base64,${image}`,
  //       });
  //     } else {
  //       console.error("Failed to fetch mask:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error while fetching mask:", error);
  //   }
  // };

  const handleChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

      if (fileExtension === "dxf") {
        const reader = new FileReader();
        reader.onload = () => {
          const parser = new DxfParser();
          const dxf = parser.parseSync(reader.result);
          const layers = [...new Set(dxf.entities.map(entity => entity.layer))];
          setDxfLayers(layers);
          setShowLayerPopup(true);
          setimageOptions({ ...imageOptions, rawImage: reader.result, fileType: "dxf" });
        };
        reader.readAsText(selectedFile);
      } else if (fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png") {
        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.getElementById("canvas");
            const ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            const aspectRatio = img.width / img.height;
            let newWidth = canvas.width;
            let newHeight = canvas.height;
            if (canvas.width / canvas.height > aspectRatio) {
              newWidth = canvas.height * aspectRatio;
            } else {
              newHeight = canvas.width / aspectRatio;
            }
            const xOffset = (canvas.width - newWidth) / 2;
            const yOffset = (canvas.height - newHeight) / 2;
            ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight);
            setimageOptions({ ...imageOptions, rawImage: reader.result, fileType: "image" });
          };
          img.src = reader.result;
        };
        reader.readAsDataURL(selectedFile);
      } else {
        console.error("Unsupported file type");
      }
      fileRef.current.value = null;
    }
  };

  const renderDXF = (dxf) => {
    const canvas = document.getElementById("canvas");
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.strokeStyle = "black";
    ctx.lineWidth = 1;

    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

    dxf.entities.forEach(entity => {
      if (selectedLayers[entity.layer]) {
        switch (entity.type) {
          case 'LINE':
            entity.vertices.forEach(vertex => {
              minX = Math.min(minX, vertex.x);
              minY = Math.min(minY, vertex.y);
              maxX = Math.max(maxX, vertex.x);
              maxY = Math.max(maxY, vertex.y);
            });
            break;
          case 'LWPOLYLINE':
          case 'POLYLINE':
            entity.vertices.forEach(vertex => {
              minX = Math.min(minX, vertex.x);
              minY = Math.min(minY, vertex.y);
              maxX = Math.max(maxX, vertex.x);
              maxY = Math.max(maxY, vertex.y);
            });
            break;
          case 'CIRCLE':
            minX = Math.min(minX, entity.center.x - entity.radius);
            minY = Math.min(minY, entity.center.y - entity.radius);
            maxX = Math.max(maxX, entity.center.x + entity.radius);
            maxY = Math.max(maxY, entity.center.y + entity.radius);
            break;
          case 'ARC':{
            const startAngleRad = (entity.startAngle * Math.PI) / 180;
            const endAngleRad = (entity.endAngle * Math.PI) / 180;
            minX = Math.min(minX, entity.center.x + entity.radius * Math.cos(startAngleRad));
            minY = Math.min(minY, entity.center.y + entity.radius * Math.sin(startAngleRad));
            maxX = Math.max(maxX, entity.center.x + entity.radius * Math.cos(endAngleRad));
            maxY = Math.max(maxY, entity.center.y + entity.radius * Math.sin(endAngleRad));
            break;
          }
          case 'SPLINE':
            entity.controlPoints.forEach(point => {
              minX = Math.min(minX, point.x);
              minY = Math.min(minY, point.y);
              maxX = Math.max(maxX, point.x);
              maxY = Math.max(maxY, point.y);
            });
            break;
          default:
            console.warn(`Unsupported entity type: ${entity.type}`);
            break;
        }
      }
    });

    const dxfWidth = maxX - minX;
    const dxfHeight = maxY - minY;
    const scale = Math.min(canvas.width / dxfWidth, canvas.height / dxfHeight);
    const offsetX = (canvas.width - dxfWidth * scale) / 2 - minX * scale;
    const offsetY = (canvas.height - dxfHeight * scale) / 2 - minY * scale;

    dxf.entities.forEach(entity => {
      if (selectedLayers[entity.layer]) {
        ctx.save();
        ctx.scale(scale, scale);
        ctx.translate(offsetX / scale, offsetY / scale);

        switch (entity.type) {
          case 'LINE':
            ctx.beginPath();
            ctx.moveTo(entity.vertices[0].x, entity.vertices[0].y);
            ctx.lineTo(entity.vertices[1].x, entity.vertices[1].y);
            ctx.stroke();
            break;
          case 'LWPOLYLINE':
          case 'POLYLINE':
            ctx.beginPath();
            entity.vertices.forEach((vertex, index) => {
              if (index === 0) {
                ctx.moveTo(vertex.x, vertex.y);
              } else {
                ctx.lineTo(vertex.x, vertex.y);
              }
            });
            if (entity.closed) {
              ctx.closePath();
            }
            ctx.stroke();
            break;
          case 'CIRCLE':
            ctx.beginPath();
            ctx.arc(entity.center.x, entity.center.y, entity.radius, 0, 2 * Math.PI);
            ctx.stroke();
            break;
          case 'ARC':
            ctx.beginPath();
            ctx.arc(entity.center.x, entity.center.y, entity.radius, (entity.startAngle * Math.PI) / 180, (entity.endAngle * Math.PI) / 180);
            ctx.stroke();
            break;
          case 'SPLINE':{
            ctx.beginPath();
            const controlPoints = entity.controlPoints.map(point => ({ x: point.x, y: point.y }));
            ctx.moveTo(controlPoints[0].x, controlPoints[0].y);
            for (let i = 1; i < controlPoints.length - 2; i++) {
              const cp1 = controlPoints[i];
              const cp2 = controlPoints[i + 1];
              ctx.quadraticCurveTo(cp1.x, cp1.y, (cp1.x + cp2.x) / 2, (cp1.y + cp2.y) / 2);
            }
            ctx.quadraticCurveTo(controlPoints[controlPoints.length - 2].x, controlPoints[controlPoints.length - 2].y, controlPoints[controlPoints.length - 1].x, controlPoints[controlPoints.length - 1].y);
            ctx.stroke();
            break;}
          default:
            console.warn(`Unsupported entity type: ${entity.type}`);
            break;
        }

        ctx.restore();
      }
    });
  };
  


  const createFingerLayout = () => {
    const canvas = document.getElementById("canvas");
    const ctx = canvas.getContext("2d");
    ctx.beginPath();
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "black";
    const rectPosition = canvas.height / (fingerOptions.fingerNumber + 1);
    for (let i = 1; i <= fingerOptions.fingerNumber; i++) {
      ctx.fillRect(
        0,
        rectPosition * (i) - fingerOptions.fingerSize / 2,
        canvas.width,
        fingerOptions.fingerSize
      ); //1280 1080
    }
    const base64Image = canvas.toDataURL('image/jpeg');
    setimageOptions({
      ...imageOptions,
      rawImage: base64Image,
      fileType: "image",
    });
    imageState = 4;
  };

  useEffect(() => {
    // if (!(imageState === 0 || imageState === 3)) {
    //   fetchMask();
    // }
    if (imageState === 3) {
      createFingerLayout();
    }
  }, [imageOptions]);

  useEffect(() => {
    imageState = 3;
    createFingerLayout();
  }, [fingerOptions, simuParams.mesh.size]);

  const handleLayerSelection = (event) => {
    const { name, checked } = event.target;
    setSelectedLayers({ ...selectedLayers, [name]: checked });
  };

  const closeLayerPopup = () => {
    setShowLayerPopup(false);
  };

  const saveLayerSelection = () => {
    const parser = new DxfParser();
    const dxf = parser.parseSync(imageOptions.rawImage);
    renderDXF(dxf);
    setShowLayerPopup(false);
    setimageOptions({ ...imageOptions, rawImage: imageOptions.rawImage, fileType: "dxf", layers: Object.keys(selectedLayers).filter(key => selectedLayers[key] === true) });
  };

  return (
    <div className="grid grid-cols-4 gap-8 w-full aspect-[5/2]">
      <div className="flex flex-col image col-span-2">
        <div style={{ width: '600px', height: '500px' }}>
          <canvas
            id="canvas"
            width={600}
            height={500}
            style={{ borderRadius: '15px', width: '100%', height: '100%' }}
          ></canvas>
        </div>
        {/* <img
          src={simuParams.mask}
          alt=""
          className="object-cover rounded-xl"
          hidden={!(imageState === 2 || imageState === 1)}
        /> */}
        <div className="flex justify-center mt-4">
          <button
            className="grid mx-auto mt-4 bg-transparent hover:bg-[#005B7F] text-[#005B7F] font-semibold hover:text-white py-2 px-4 border-2 border-[#005B7F] hover:border-transparent rounded-xl"
            onClick={createFingerLayout}
          >
            Use Synthetic Fingers
          </button>
        </div>
        <div className="flex justify-center mt-4">
          <button
            className="grid mx-auto mt-4 bg-transparent hover:bg-[#005B7F] text-[#005B7F] font-semibold hover:text-white py-2 px-4 border-2 border-[#005B7F] hover:border-transparent rounded-xl"
            onClick={() => fileRef.current.click()}
          >
            <input
              id="upload"
              name="upload"
              type="file"
              ref={fileRef}
              hidden
              onChange={handleChange}
            />
            Upload File &#40;dxf, jpg, png, jpeg&#41;
          </button>
        </div>
      </div>
      <div className="grid grid-cols-2 mb-auto gap-8 col-span-2">
        <p className="leading-loose font-poppins font-bold text-align-top col-span-2">
          Customize your Finger mask!
        </p>
        <div className="flex flex-col items-center col-span-2">
          <p className="leading-loose font-poppins font-bold col-span-2">
            Set the number of fingers!
          </p>
          <input
            className="w-full bg-indigo-600 text-indigo-600"
            type="range"
            min="1"
            max="10"
            onInput={(event) => {
              event.preventDefault();
              setFingerOptions({
                ...fingerOptions,
                fingerNumber: parseInt(event.target.value),
              });
            }}
            value={fingerOptions.fingerNumber}
          />
          <div className="w-32">
            <div className="flex p-2 flex-row w-full rounded-lg relative bg-transparent mt-1">
              <button
                onClick={() => {
                  if (fingerOptions.fingerNumber > 1) {
                    setFingerOptions({
                      ...fingerOptions,
                      fingerNumber: parseInt(fingerOptions.fingerNumber) - 1,
                    });
                  }
                }}
                className="font-bold border-2 border-white bg-white hover:border-indigo-600 h-full w-20 rounded-l-lg cursor-pointer"
              >
                <span>−</span>
              </button>
              <input
                type="number"
                value={fingerOptions.fingerNumber}
                onInput={(event) =>
                  setFingerOptions({
                    ...fingerOptions,
                    fingerNumber: parseInt(event.target.value),
                  })
                }
                className="appearance-none w-12 block border-2 border-white focus:outline-none hover:border-indigo-600 text-center bg-white font-semibold text-md hover:text-black focus:text-black md:text-base cursor-default items-center text-gray-700 outline-none"
              ></input>
              <button
                onClick={() => {
                  if (fingerOptions.fingerNumber < 10) {
                    setFingerOptions({
                      ...fingerOptions,
                      fingerNumber: parseInt(fingerOptions.fingerNumber) + 1,
                    });
                  }
                }}
                className="font-bold border-2 border-white bg-white hover:border-indigo-600 h-full w-20 rounded-r-lg cursor-pointer"
              >
                <span>+</span>
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center col-span-2">
          <p className="leading-loose font-poppins font-bold col-span-2">
            Set the size of the fingers!
          </p>
          <input
            className="w-full bg-indigo-600 text-indigo-600"
            type="range"
            min="1"
            max="100"
            onInput={(event) => {
              event.preventDefault();
              setFingerOptions({
                ...fingerOptions,
                fingerSize: parseInt(event.target.value),
              });
            }}
            value={fingerOptions.fingerSize}
          />
          <div className="w-32">
            <div className="flex p-2 flex-row w-full rounded-lg relative bg-transparent mt-1">
              <button
                onClick={() => {
                  if (fingerOptions.fingerSize > 1) {
                    setFingerOptions({
                      ...fingerOptions,
                      fingerSize: parseInt(fingerOptions.fingerSize) - 1,
                    });
                  }
                }}
                className="font-bold border-2 border-white bg-white hover:border-indigo-600 h-full w-20 rounded-l-lg cursor-pointer"
              >
                <span>−</span>
              </button>
              <input
                type="number"
                value={fingerOptions.fingerSize}
                onInput={(event) =>
                  setFingerOptions({
                    ...fingerOptions,
                    fingerSize: parseInt(event.target.value),
                  })
                }
                className="appearance-none w-12 block border-2 border-white focus:outline-none hover:border-indigo-600 text-center bg-white font-semibold text-md hover:text-black focus:text-black md:text-base cursor-default items-center text-gray-700 outline-none"
              ></input>
              <button
                onClick={() => {
                  if (fingerOptions.fingerSize < 100) {
                    setFingerOptions({
                      ...fingerOptions,
                      fingerSize: parseInt(fingerOptions.fingerSize) + 1,
                    });
                  }
                }}
                className="font-bold border-2 border-white bg-white hover:border-indigo-600 h-full w-20 rounded-r-lg cursor-pointer"
              >
                <span>+</span>
              </button>
            </div>
          </div>
        </div>
        {imageOptions.fileType === "dxf" && (
          <div className="flex flex-col items-center col-span-2">
            <p className="leading-loose font-poppins font-bold col-span-2">
              Select the layers of your dxf file!
            </p>
            <button
              className="mt-4 bg-transparent hover:bg-[#005B7F] text-[#005B7F] font-semibold hover:text-white py-2 px-4 border-2 border-[#005B7F] hover:border-transparent rounded-xl"
              onClick={() => setShowLayerPopup(true)}
            >
              Layer Selection
            </button>
          </div>
        )}
      </div>

      {showLayerPopup && (
        <div className="popup-overlay">
          <div className="popup" style={{ width: '400px' }}>
            <h2 className="text-xl font-bold mb-4">Layer Selection</h2>
            <div className="mb-4" style={{ maxHeight: '300px', overflowY: 'auto' }}>
              {dxfLayers.map((layer, index) => (
                <div key={index} className="mb-2">
                  <label>
                    <input
                      type="checkbox"
                      name={layer}
                      checked={!!selectedLayers[layer]}
                      onChange={handleLayerSelection}
                    />
                    {layer}
                  </label>
                </div>
              ))}
            </div>
            <div className="flex justify-end">
              <button
                className="bg-transparent hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 border border-gray-400 rounded mr-2"
                onClick={closeLayerPopup}
              >
                Cancel
              </button>
              <button
                className="bg-[#179C7D] hover:bg-[#148C70] text-white font-bold py-2 px-4 rounded"
                onClick={saveLayerSelection}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageSection;
