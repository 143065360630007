import React from "react";
import { fraunhofer_gray } from "./svgs";
import '../styles/button.css'; // Import the CSS file

function Landing(props) {
  return (
    <div
      className="grid grid-cols-4 gap-4 py-16 bg-no-repeat bg-cover"
      //   style={{
      //     backgroundImage: `linear-gradient(to bottomleft, rgba(0,0,0,0),
      //     rgba(229, 231, 235,1)), url(/REM.png)`,
      //   }}
    >
      <div className=" col-span-2">
        <div className="mb-16">{fraunhofer_gray}</div>
        <h1 id="Landing" className=" text-7xl mb-16">
          Screen Design Toolbox
        </h1>
        <p className="tr tracking-tight w-3/4">
          The screen design toolbox offers a fast, reliable and easy to use
          method to evaluate and optimize potential screen architectures and layout positions for any
          printing layout. Convince yourself
          of the flexibility and potential of our algorithm by evaluating screen design
          options for arbitrary images.
        </p>
        <button
          onClick={() => props.scrollTo("Mesh")}
          //[#179C7D] className="mx-auto my-8 bg-indigo-600 cursor-pointer hover:bg-gray-200 text-gray-200 font-semibold hover:text-indigo-600 py-2 px-8 border-2 border-indigo-600 hover:border-indigo-600 hover:border-transparent rounded"
          className='download-button'
          >
          Get started!
        </button>
      </div>
      <div
        className="bg-no-repeat bg-cover col-start-3 col-span-2 bg-gradient-to-l from-black/100 to-black/100"
        // className="w-full bg-gradient-to-r from-purple-400/25 via-pink-500/25 to-red-500/25 rounded p-6 mt-4"
        style={{
          backgroundImage: `linear-gradient(to left, rgba(0,0,0,0),
          rgba(229, 231, 235,1)), url(/REM.png)`,
        }}
      ></div>
    </div>
  );
}

export default Landing;
