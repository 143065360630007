import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useRecoilState } from "recoil";
import { simuParameters, globalSimuResults, sweepIterationsState, baseUrl } from "../App";
import axios from "axios";
import { Loader, plusSvg } from "./svgs";
import SimuRow from "./SimuRow";
import { GrNodes } from "react-icons/gr";

const params = [
  {
    fullName: "Meshcount",
    shortName: "mc",
    unit: "1/Inch",
    range: { lower: 50, upper: 700 },
  },
  {
    fullName: "Meshcount Weft",
    shortName: "mcx",
    unit: "1/Inch",
    range: { lower: 50, upper: 700 },
  },
  {
    fullName: "Meshcount Warp",
    shortName: "mcy",
    unit: "1/Inch",
    range: { lower: 50, upper: 700 },
  },
  {
    fullName: "Wire diameter",
    shortName: "d",
    unit: "µm",
    range: { lower: 5, upper: 50 },
  },
  {
    fullName: "Wire diameter Weft",
    shortName: "dx",
    unit: "µm",
    range: { lower: 5, upper: 50 },
  },
  {
    fullName: "Wire diameter Warp",
    shortName: "dy",
    unit: "µm",
    range: { lower: 5, upper: 50 },
  },
  {
    fullName: "Kappa",
    shortName: "k",
    unit: "-",
    range: { lower: 0, upper: 1 },
  },
  {
    fullName: "Kappa Weft",
    shortName: "kx",
    unit: "-",
    range: { lower: 0, upper: 1 },
  },
  {
    fullName: "Kappa Warp",
    shortName: "ky",
    unit: "-",
    range: { lower: 0, upper: 1 },
  },
  {
    fullName: "Theta",
    shortName: "theta",
    unit: "deg",
    range: { lower: 45, upper: 90 },
  },
  {
    fullName: "Phi",
    shortName: "phi",
    unit: "deg",
    range: { lower: 0, upper: 45 },
  },
];


function SimuSection({ imageOptions }) {
  const simuUrl = baseUrl + "/simu/";



  const [, setsimResults] = useRecoilState(globalSimuResults);
  const [simuParams] = useRecoilState(simuParameters);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [notUsedParams] = useState(params);
  const [rows, setRows] = useState([]);
  const [sweepIterations, setSweepIterations] = useRecoilState(sweepIterationsState); // New state for iterations

  const fetchSimulation = async () => {
    console.log("Sending data:", {
      mesh: simuParams.mesh,
      sweepInfo: simuParams.sweepParams,
      sweepIterations: sweepIterations,
    });
    if (!checkForErrors()) {
      setloading(true);

        const fileSaveUrl = baseUrl + "/file_save/";
        let layoutInfo = { layers: null, fileUrl: null };
        if (imageOptions.fileType === "dxf" && imageOptions.rawImage
          && imageOptions.layers.length > 0)
          layoutInfo.layers = imageOptions.layers;
          const saveFileData = await saveFileFormData(imageOptions);
        try {
          const fileResponse =await axios.post(fileSaveUrl,saveFileData)
          const filedata = await fileResponse.data;
            layoutInfo.fileUrl = filedata.fileUrl;
          } catch (error) {
            console.error("Request failed:", error);
            seterror(error.response.data.detail);
          }
          const jsonData = {
            "layoutInfo":
            layoutInfo,
            "mesh": simuParams.mesh,
            "sweepInfo": simuParams.sweepParams,
            "sweepIterations": sweepIterations
          };
              try {
                 await axios.post(simuUrl, jsonData,
                    { timeout: 900000 })
                    .then(response => {
                      console.log("Response:", response);
                      setsimResults(response.data);  
                    });
              } catch (error) {
                console.error("Request failed:", error);
                seterror(error.response?.data?.detail);

              }
    } else {
      console.log("found error:", error);
    }
    setloading(false);
  };

  const saveFileFormData = async (imageOptions) => {
    const formData = new FormData();
    if (imageOptions.fileType === "image" &&
      imageOptions.rawImage && imageOptions.rawImage.startsWith("data:image/")) {
      const base64Image = imageOptions.rawImage.split(",")[1];
      const byteCharacters = atob(base64Image);
      const byteNumbers = new Array(byteCharacters.length)
        .fill(0)
        .map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg" });
      formData.append("file", blob, "image.jpg");
    } else if (imageOptions.fileType === "dxf" && imageOptions.rawImage && imageOptions.layers.length > 0) {
      const dxfBlob = new Blob([imageOptions.rawImage], { type: "application/dxf" });
      formData.append("file", dxfBlob, "file.dxf");
    } else {
      console.error("No valid file to upload or unsupported file type");
      return;
    }
    return formData;
  }



  const checkForErrors = () => {
    if (hasOverlapParameters()) {
      return true;
    }
    // if (!simuParams.mask) {
    //   seterror("Create a mask first!");
    //   return true;
    // }
    if (Object.keys(simuParams.sweepParams).length === 0) {
      seterror("Define at least one optimization parameter!");
      return true;
    }
    seterror(false);
    return false;
  };

  const addNewRow = async () => {
    if (rows.length === 0) {
      setRows(rows.concat(0));
    } else {
      setRows(rows.concat(rows[rows.length - 1] + 1));
    }
  };

  const hasOverlapParameters = useCallback(() => {
    const wantedSweepParams = simuParams.sweepParams;
    let names = [];
    for (const key in wantedSweepParams) {
      if (wantedSweepParams[key]) {
        names.push(wantedSweepParams[key].name);
      }
    }
    //console.log(names);
    const overlaps = ["mc", "d", "k"];
    for (const i in overlaps) {
      let count = 0;
      const overlap = overlaps[i];
      for (const j in names) {
        const name = names[j];
        if (name.includes(overlap)) {
          count = count + 1;
        }
        if (count > 1) {
          seterror("Contradiction in Parameters!");
          console.log("has overlap", overlap);
          return true;
        }
      }
    }
    console.log("no overlap");
    return false;
  }, [simuParams.sweepParams]);
  
  useEffect(() => {
    //console.log("SimuSection useEffect triggered");
    //console.log(simuParams);
     if (error) {
    //   if (error === "Create a mask first!" && simuParams.mask) {
    //     seterror(false);
    //   }
      if (error === "Contradiction in Parameters!" && !hasOverlapParameters()) {
        seterror(false);
      }
    }
  }, [simuParams.sweepParams, simuParams, hasOverlapParameters, error]);

  return (
    <div className="flex flex-col w-full">
      <div className="grid grid-cols-4 gap-6 mb-8">
        <button
          onClick={addNewRow}
          className="group w-full col-start-2 relative disabled:cursor-not-allowed bg-transparent hover:bg-[#005B7F] text-[#005B7F] font-semibold hover:text-white py-2 px-8 border-2 border-[#005B7F] hover:border-transparent rounded-xl"
        >
          <div className="flex items-center justify-center">
            <div className="inset-y-0 mr-4 w-8 h-8">{plusSvg}</div>
            Parameter
          </div>
        </button>
        <div>
          <button
            className={
              error
                ? "cursor-not-allowed w-full group col-start-2 relative bg-transparent hover:bg-red-600 text-red-600 font-semibold hover:text-white py-2 px-8 border-2 border-red-600 hover:border-transparent rounded-xl"
                : "disabled:cursor-not-allowed w-full group col-start-2 relative bg-transparent hover:bg-[#005B7F] text-[#005B7F] font-semibold hover:text-white py-2 px-8 border-2 border-[#005B7F] hover:border-transparent rounded-xl"
            }
            onClick={fetchSimulation}
            disabled={loading ? true : false}
          >
            <div className="flex items-center justify-center">
              {loading ? (
                <>
                  <GrNodes size={32} className="mr-4" />
                  Computing...
                </>
              ) : (
                <>
                  <GrNodes size={32} className="mr-4" />
                  Simulate!
                </>
              )}
            </div>
            <div className="absolute right-0 inset-y-0 m-2">
              {loading ? Loader : null}
            </div>
          </button>
        </div>
        <div className="flex flex-col items-center">
          <input
            type="range"
            value={sweepIterations}
            onChange={(e) => setSweepIterations(Math.min(Math.max(e.target.value, 10), 200))}
            min="100"
            max="200"
            step="10"
            className="w-80 bg-[#005B7F] text-indigo-[#005B7F]"
          />
          <label className="leading-loose font-poppins text-align-top col-span-2">Number of Optimization cycles: {sweepIterations}</label>
        </div>
        <p
          className={
            error
              ? "text-red-500 row-start-2 col-start-3 text-xs italic"
              : "hidden text-red-500 row-start-2 col-start-3 text-xs italic"
          }
        >
          {error}
        </p>
      </div>
      {rows.map((id) => {
        return (
          <SimuRow
            key={id}
            id={id}
            notUsedParams={notUsedParams}
            setRows={setRows}
            rows={rows}
          />
        );
      })}
    </div>
  );
}

export default SimuSection;
